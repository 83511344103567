<template>
  <b-form @submit.stop.prevent="confirm">
    <b-modal :id="`confirmModal_${id}`" centered size="md" @hide="close">
      <template #modal-title> {{ title }} </template>
      <div>
        <p>
          {{ description }}
        </p>
      </div>
      <template #modal-footer>
        <div
          class="d-flex flex-column align-items-center justify-content-between w-100"
        >
          <b-button
            type="submit"
            variant="primary"
            @click="confirm"
            class="w-50"
            >{{ confirmLabel }}</b-button
          >
          <b-button
            type="reset"
            variant="link"
            @click="close"
            class="text-dark text-hover-dark-50 text-decoration-none font-size-md mt-5 p-0"
            >İPTAL</b-button
          >
        </div>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    id: {
      type: String,
      default: new Date().getTime().toString(),
    },
    importApiEndpoint: {
      type: String,
      default: '',
    },
    show: Boolean,
    title: {
      type: String,
      default: 'Formu Onaya Gönder',
    },
    description: {
      type: String,
      default: 'Form onaya gönderilecek. Onaylıyor musunuz?',
    },
    resetForm: Boolean,
    confirmLabel: {
      type: String,
      default: 'GÖNDER',
    },
    confirmEmitName: {
      type: String,
      default: '',
    },
    selectedItemId: {
      type: String,
      default: '',
    },
  },
  methods: {
    confirm() {
      let payload = '';
      if (this.selectedItemId && this.selectedItemId !== '') {
        payload = {
          selectedItemId: this.selectedItemId,
          type: this.confirmEmitName,
        };
      } else {
        payload = this.confirmEmitName;
      }

      this.$emit('confirmOperation', payload);
      this.$bvModal.hide(`confirmModal_${this.id}`);
    },
    close() {
      this.$bvModal.hide(`confirmModal_${this.id}`);
      this.$emit('closeDetail');
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$bvModal.show(`confirmModal_${this.id}`);
      } else this.$bvModal.hide(`confirmModal_${this.id}`);
    },
  },
};
</script>
